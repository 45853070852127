<template>
  <div>
    <div>
      <b-card class="mb-1">
        <b-form-group v-slot="{ voicemailGreeting }" label="Greeting type:">
          <b-form-radio-group
            id="voicemailGreeting"
            v-model="greetingTypeSelected"
            :options="optionsForGreeting"
            :aria-describedby="voicemailGreeting"
            name="radio-options-greeting"
            size="md"
            stacked
          />
        </b-form-group>
        <div v-if="greetingTypeSelected === 'custom'">
          <b-button @click="showRecorder">
            Manage Custom Greeting
          </b-button>
        </div>
      </b-card>
      <b-card class="mb-1">
        <b-form-group v-slot="{ ringCount }" label="Number of rings before forwarding to voicemail: ">
          <b-form-radio-group
            id="ringCount"
            v-model="ringCountSelected"
            :options="ringCountOptions"
            :aria-describedby="ringCount"
            name="radio-options-ring-count"
            size="md"
            stacked
          />
        </b-form-group>
      </b-card>
      <b-card class="mb-1">
        <b-form-group v-slot="{ voicemailRetention }" label="Voicemail retention period: ">
          <b-form-radio-group
            id="voicemailRetention"
            v-model="retentionPeriodSelected"
            :options="optionsForVoicemailRetention"
            :aria-describedby="voicemailRetention"
            name="radio-options-retention"
            size="md"
            stacked
          />
        </b-form-group>
      </b-card>
      <b-card>
        <div>
          <b-form-group label="Voicemail recording length:">
            <b-form-radio-group
              id="voicemailDuration"
              v-model="voicemailDuration"
              :options="optionsForVoicemailDuration"
              :aria-describedby="voicemailDuration"
              name="radio-options-duration"
              size="md"
              stacked
            />
          </b-form-group>
        </div>
      </b-card>
    </div>
    <b-button class="btn-primary button-settings" @click="submit">
      Save Settings
    </b-button>
    <virtual-phone-voicemail-recorder-modal
      ref="virtual-phone-voicemail-recorder-modal"
      :custom-voicemail-url="customVoicemailUrl"
      :upload-url="uploadUrl"
      @success="successfulUpload"
      @failure="failedUpload"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VirtualPhoneVoicemailRecorderModal from "@/components/VirtualPhones/VirtualPhoneVoicemailRecorderModal"
import http from '@/http'

export default {
  name: "VirtualPhoneCustomVoicemailSettings",
  components: { VirtualPhoneVoicemailRecorderModal },
  data() {
    return {
      voicemailDuration: this.$store.getters["virtualPhones/selectedPhone"].voicemail_duration || '300',
      retentionPeriodSelected: this.$store.getters["virtualPhones/selectedPhone"].voicemail_lifespan_in_days || '14',
      greetingTypeSelected: this.$store.getters["virtualPhones/selectedPhone"].greeting_type || 'generic',
      ringCountSelected: this.$store.getters["virtualPhones/selectedPhone"].ring_count || '25',
      ringCountOptions: [
        { text: '3 rings', value: '15' },
        { text: '5 rings', value: '25' },
        { text: '6 rings', value: '30' },
      ],
      optionsForVoicemailDuration: [
        { text: '1 Minute', value: '60' },
        { text: '3 Minutes', value: '180' },
        { text: '5 Minutes', value: '300' },
      ],
      optionsForVoicemailRetention: [
        { text: '2 Weeks', value: '14' },
        { text: '6 Weeks', value: '60' },
        { text: '6 Months', value: '182' },
      ],
      optionsForGreeting: [
        { text: `"The dialed number is not available, please leave a message after the beep."`, value: 'generic' },
        { text: 'Single Beep', value: 'beep' },
        { text: 'Custom Greeting', value: 'custom' },
      ],
    }
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'customVoicemailUrl', 'voicemailLifespanInDays', 'greetingType']),
    uploadUrl() {
      return `client/virtual_phones/${this.selectedPhone.id}/upload-voicemail`
    },
  },

  async mounted() {
    while(this.selectedPhone == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
  },

  methods: {
    ...mapActions('virtualPhones', ['updateVoicemailSettings', 'fetchCurrentCustomVoicemailUrl']),
    successfulUpload(){
      this.saveVoicemailSettings()
      this.$refs['virtual-phone-voicemail-recorder-modal'].hide()
    },
    failedUpload(){
      this.$bvToast.toast('Voicemail Greeting failed to Upload', {
        title: 'Error',
        autoHideDelay: 2000,
      })
      this.$refs['virtual-phone-voicemail-recorder-modal'].hide()
    },
    errorToast(error) {
      // TODO: Update to new Toast Mixin
      this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
    },
    goodToastForVoicemailSetting() {
      this.$bvToast.toast('Settings have been saved', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 1000,
      })
    },
    settingsSaved(){
      this.saveVoicemailSettings()
    },
    async saveVoicemailSettings(){
      let params = {
        retentionPeriod: this.retentionPeriodSelected,
        greetingType: this.greetingTypeSelected,
        voicemailDuration: this.voicemailDuration,
        ringCount: this.ringCountSelected,
      }

      this.updateVoicemailSettings({
        updatedParams: params,
        errorToast: this.errorToast,
        goodToast: this.goodToastForVoicemailSetting,
      })
    },
    showRecorder(){
      this.$refs['virtual-phone-voicemail-recorder-modal'].show()
    },
    submit(){
      this.settingsSaved()
    },
  },
}
</script>

<style lang="scss" scoped>

.button-settings{
  margin-top: 10px;
}

.voicemail-text{
  margin-top: 25px;
}

.recorder{
  margin-left: 190px;
}
</style>
