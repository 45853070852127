<template>
  <b-modal
    ref="virtual-phone-voicemail-recorder-modal"
    size="lg"
    title="Record Your Custom Voicemail Greeting"
  >
    <div>
      <div>
        <label>Record New Greeting</label><br>
        <b-button :class="{red : recordingInProgress}" @click="onButtonPress">
          {{ displayText }}
        </b-button>
      </div><br>
      <div v-if="recordList">
        <label>Saved Greeting</label>
        <c-t-audio-controls :audio-source="preferredAudioSource" />
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }" style="align-content: center">
      <b-button @click="cancel()">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Recorder from '@/lib/recorder'
import CTAudioControls from '@/components/CTAudioControls'
import UploaderPropsMixin from '@/mixins/uploader-props'
import http from '@/http'

export default {
  name: "VirtualPhoneVoicemailRecorderModal",
  components: { Recorder, CTAudioControls },
  mixins: [UploaderPropsMixin],
  props: {
    uploadUrl: String,
    customVoicemailUrl: String,
  },
  data(){
    return {
      showCustomVoicemailRecording: false,
      showRecorder: true,
      showType: 'Show',
      recorder: this._initRecorder(),
      record: { type: Object },
      micFailed: { type: Function },
      data: FormData,
      headers: Object,
      recordList: [],
      recordingInProgress: false,
    }
  },

  computed: {
    preferredAudioSource() {
      return this.recordList[0] || this.customVoicemailUrl
    },

    displayText() {
      return this.recordingInProgress ? "Stop" : "Start Recording"
    },
  },

  methods: {
    show(){
      this.$refs['virtual-phone-voicemail-recorder-modal'].show()
    },
    hide(){
      this.$refs['virtual-phone-voicemail-recorder-modal'].hide()
    },
    successfulUpload(){
      this.$emit('success')
    },
    failedUpload(){
      this.$emit('failure')
    },
    noVoicemail(){
      this.$bvToast.toast('No voicemail was recorded, please record a voicemail and try again', {
        title: 'Error',
        autoHideDelay: 2000,
      })
    },
    showCustomVoicemail(){
      this.showType = this.showCustomVoicemailRecording ? 'Show' : 'Hide'
      this.showCustomVoicemailRecording = !this.showCustomVoicemailRecording
    },
    uploadRecording(){
      if (this.record){
        this.data = new FormData()
        this.data.append('audio', this.record.blob, `${this.filename}.mp3`)

        this.headers = Object.assign(this.headers, {})
        this.data.boundry = this.setBoundary()
        this.headers['Content-Type'] = `multipart/form-data; boundary=${this.data.boundary}`
        http.post(this.uploadUrl, this.data, { headers: this.headers }).then(_resp => {
          this.successfulUpload()
        }).catch(_error => {
          this.failedUpload()
        })
      } else {
        this.noVoicemail()
      }
    },
    setBoundary(){
      let boundary = '--------------------------'
      for (let i = 0; i < 24; i++) {
        boundary += Math.floor(Math.random() * 10).toString(16)
      }
      return boundary
    },
    resetRecorder(){
      this.recorder = this._initRecorder()
    },
    resetRecordList(){
      this.recordList = []
    },
    onButtonPress() {
      if (!this.recordingInProgress)  {
        this.startRecording()
      } else {
        this.stopRecording()
        this.uploadRecording()
      }
    },
    startRecording(){
      this.recorder.start()
      this.recordingInProgress = true
      this.resetRecordList()
    },
    stopRecording(){
      if (this.recorder.duration > 0){
        this.recorder.stop()
        this.recordingInProgress = false
        this.record = this.recorder.records[0]
        this.recordList.push(this.record.url)
        this.resetRecorder()
      } else {
        this.noVoicemail()
      }
    },
    _initRecorder () {
      return new Recorder({
        micFailed: this.micFailed,
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.red {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  background-color: red;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.start-recording{
  margin-left: 10px;
}

.player{
  margin-top: 10px;
}

.show-hide-button{
  margin-top: 10px;
}

div.ar::v-deep div.ar-icon {
  padding: 0;
  border: none;
  box-shadow: 0 2px 5px 1px rgba(158, 158, 158, 0.5)
}

play{
  border: none !important;
  padding: 0 !important;
  box-shadow: 0 2px 5px 1px rgba(158, 158, 158, 0.5) !important;
}

.custom-voicemail-player{
  margin-top: 10px;
}
</style>
